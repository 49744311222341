import React from 'react'
import { KeyDates } from '@/components/KeyDates'

export default function Sidebar() {
  return (
    <>
      <div className="px-4">
          <h3 className="lg:text-3xl mt-0">Key Dates</h3>
          <KeyDates />
      </div>
    </>
  )
}
